.font-size-2 {
	font-size: calc(0.8rem * 1.618);
}

.mt-4:not(:first-child) { margin-top: 1rem; }

.border { border-width: 1px; }

.border-2 { border-width: 2px; }

.w-16 { width: 4rem; }

.mx-auto { margin-right: auto; margin-left: auto; }

.font-bold { font-weight: 700; }

.list-inside { list-style-position: inside; }

.list-inside > li{
	margin-top: 0.5rem;
}
.text-base { font-size: calc(0.8rem * 1.618); }

.text-opacity-75 { --text-opacity: 75; }

.py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem; }

.px-5 { padding-right: 1.25rem; padding-left: 1.25rem; }

.italic { font-style: italic; }

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.table-auto { table-layout: auto; }

.font > * > a, .font > a {
	text-decoration: none;
	color: var(--white);
	text-decoration: underline;
	transition: all .2s ease;
}

.font > * > a:hover, .font > a {
	color: var(--gray);
}