* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Barlow', sans-serif;
}

html {
	--gray: #969696;
	--blue-dark: #162840;
	--blue-dark-17: #1628402b;
	--blue-dark-60: #16294199;
	--white: #f2f2f2;
	--white-95: #f2f2f2f2;
	--black: #1f1f1f;
	--black-95: #1f1f1ff2;
	--blue: #8C6214;
}