/* gives us the illusion of a "centered" slide */

@media only screen and (min-width: 1300px) {
	.carousel__slider {
		padding-left: 20%;
		padding-right: 20%;
	}
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
	width: 100%;
  height: calc(100% - 20px);
  /* background-color: burlywood; */
}